var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "partners-wrap" }, [
    _c(
      "div",
      { staticClass: "partners-content" },
      [
        _vm._t("default"),
        _vm._v(" "),
        _vm._l(_vm.partnersList, function (item, index) {
          return _c("div", { key: index, staticClass: "partner-item" }, [
            _c("div", { staticClass: "item-title" }, [
              _vm._v(
                "\n                " + _vm._s(item.title) + "\n            "
              ),
            ]),
            _vm._v(" "),
            _vm.mounted
              ? _c(
                  "div",
                  {
                    staticClass: "partners-box",
                    style: { height: !_vm.isMdScreen ? "50px" : "" },
                  },
                  [
                    _c(
                      "div",
                      [
                        _c("div", {
                          class: "cover cover1 " + _vm.coverClass + "-1",
                        }),
                        _vm._v(" "),
                        _c(
                          "vue-seamless-scroll",
                          {
                            key: _vm.scrollKey + index,
                            staticClass: "swiperWidth",
                            attrs: {
                              data: item.images,
                              "class-option": _vm.classOption(
                                item.images.length
                              ),
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "box-wrap" },
                              _vm._l(item.images, function (it, ix) {
                                return _c(
                                  "div",
                                  { key: ix, staticClass: "box-item" },
                                  [
                                    _c("ec-image", [
                                      _c("img", {
                                        staticClass: "box-item",
                                        attrs: { loading: "lazy", src: it },
                                      }),
                                    ]),
                                  ],
                                  1
                                )
                              }),
                              0
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", {
                          class: "cover cover2 " + _vm.coverClass + "-2",
                        }),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
          ])
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }