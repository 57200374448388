var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.mounted
    ? _c("div", [
        _c(
          "div",
          { staticClass: "home-user-pc  d-none d-md-block" },
          [
            _c("div", { staticClass: "ph-title slide-title" }, [
              _c("div", [_vm._v(_vm._s(_vm.getI18n("title")))]),
            ]),
            _vm._v(" "),
            _c(
              "swiper",
              { ref: "mySwiper", attrs: { options: _vm.swiperOptions } },
              _vm._l(_vm.userList, function (item, index) {
                return _c("swiper-slide", { key: index }, [
                  _c(
                    "div",
                    {
                      staticClass: "slide-box",
                      style: { "background-image": "url(" + item.bgUrl + ")" },
                    },
                    [
                      _c("div", { staticClass: "slide" }, [
                        _c(
                          "div",
                          { staticClass: "left" },
                          [
                            _c("ec-image", [
                              _c("img", {
                                staticClass: "left-img",
                                attrs: { src: item.icon, loading: "lazy" },
                              }),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "right" }, [
                          _c("div", { staticClass: "bottom-box" }, [
                            _c("div", { staticClass: "bottom-title-box" }, [
                              _c("div", { staticClass: "bottom-title" }, [
                                _vm._v(
                                  "\n                                        " +
                                    _vm._s(item.name) +
                                    "\n                                    "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("img", {
                                staticClass: "bottom-sex",
                                attrs: { src: item.sex },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "bottom-content" }, [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(item.said) +
                                  "\n                                "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "bottom-star",
                              attrs: { src: require("../img/star1.png") },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "bottom-box-two" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "bottom-box-three" }),
                        ]),
                      ]),
                    ]
                  ),
                ])
              }),
              1
            ),
            _vm._v(" "),
            _vm._m(0),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "home-user-mobile d-block d-md-none" },
          [
            _c("div", { staticClass: "slide-title" }, [
              _vm._v(
                "\n            " + _vm._s(_vm.getI18n("title")) + "\n        "
              ),
            ]),
            _vm._v(" "),
            _c(
              "swiper",
              { ref: "mySwiper", attrs: { options: _vm.swiperOptions } },
              [
                _vm._l(_vm.userList, function (item, index) {
                  return _c("swiper-slide", { key: index }, [
                    _c(
                      "div",
                      {
                        staticClass: "slide-box",
                        style: {
                          "background-image": "url(" + item.mBgUrl + ")",
                        },
                      },
                      [
                        _c("div", { staticClass: "bottom-box" }, [
                          _c(
                            "div",
                            { staticClass: "bottom-title-box" },
                            [
                              _c("ec-image", [
                                _c("img", {
                                  staticClass: "bottom-img",
                                  attrs: { src: item.icon, loading: "lazy" },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "bottom-title" }, [
                                _c("div", [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(item.name) +
                                      "\n                                "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("img", {
                                  staticClass: "bottom-star",
                                  attrs: { src: require("../img/star1.png") },
                                }),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "bottom-content" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(item.said) +
                                "\n                        "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "bottom-box-two" }),
                        _vm._v(" "),
                        _c("div", { staticClass: "bottom-box-three" }),
                      ]
                    ),
                  ])
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "swiper-pagination",
                  attrs: { slot: "pagination" },
                  slot: "pagination",
                }),
              ],
              2
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container-box" }, [
      _c("div", { staticClass: "container-wrap" }, [
        _c("img", {
          staticClass: "swiper-button-prev-self prev-self",
          attrs: { src: require("../img/left-arrow.svg") },
        }),
        _vm._v(" "),
        _c("img", {
          staticClass: "swiper-button-next-self next-self",
          attrs: { src: require("../img/right-arrow.svg") },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }