<!-- @Author: wuhuiwang -->
<!-- @Date: 2023-12-28 19:25:34 -->
<!-- @Last Modified by: wangwuhui -->
<!-- @Last Modified time: 2023-12-29 15:46:59 -->

<template>
    <div
        :class="{ 'home-activity-hide': blogList.length !== 2 }"
        class="home-activity"
    >
        <div class="activite-title">
            <span>{{ getI18n('title') }}</span>
            <div
                class="more"
                @click="goPage('blogs')"
            >
                {{ getI18n('more') }}
            </div>
        </div>
        <div class="activite-wrap">
            <router-link
                v-for="(item, index) in blogList"
                :key="index"
                class="activite-box"
                :to="{
                    name: 'blogpage',
                    params: {blogPath: item.path}}"
            >
                <ec-image>
                    <img
                        loading="lazy"
                        :src="item.headerImageUrl"
                        alt=""
                        class="left-img"
                    >
                </ec-image>

                <div class="center-box">
                    <div class="desc">
                        {{ item.title }}
                    </div>
                    <div class="title">
                        {{ formatTime(item.timeCreated) }}
                    </div>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs';

import ua from 'ssr-common/util/webview/ua-parsed';
import prefetch from 'ssr-common/vue/mixin/server-prefetch';

import Config from 'easycash/common/resource/blog';
import 'dayjs/locale/id';

export default {
    name: 'BorrowActivity',

    mixins: [prefetch('borrowBlog')],

    data() {
        return {
            ua
        };
    },

    computed: {
        blogList() {
            return this.borrowBlog?.body?.blogList || [];
        }
    },

    methods: {
        getI18n(key, params) {
            return this.$t(`borrow.activity.${key}`, params);
        },

        goPage(name) {
            this.$router.push({name});
        },

        formatTime(time) {
            return dayjs(time).locale(this.$i18n.locale).format('DD MMMM YYYY');
        },

        async initFetch() {
            const {data: {body}} = await Config.homeActive();

            return {body};
        }
    }

};
</script>

<style lang="scss" scoped>
@import "~mabilis-cash/common/style/index";

.home-activity {
    &-hide {
        display: none;
    }

    .activite-title {
        color: $base-black;
        font-size: 46px;
        font-weight: 800;
        line-height: 130%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: $screen-md) {
            font-size: 24px;
            line-height: 1;
        }

        .more {
            width: 79px;
            height: 40px;
            border-radius: 30px;
            border: 1px solid #7e7e8a;
            color: #7e7e8a;
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover,
            &:active {
                background-color: #e8ecff;
            }

            @media screen and (max-width: $screen-md) {
                width: 40px;
                height: 20px;
                font-size: 7px;
                border-radius: 10px;
            }
        }
    }

    .activite-wrap {
        margin-top: 40px;
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: $screen-md) {
            flex-direction: column;
            justify-content: unset;
            margin-top: 20px;
        }

        .activite-box {
            display: flex;
            align-items: center;
            width: 49%;
            box-sizing: border-box;
            border-radius: 32px;
            background: $base-white;
            padding: 10px 30px 10px 10px;
            border: 2px solid rgba(255, 255, 255, 0.5);

            &:hover {
                // background: #333;
                .left-img {
                    transform: scale(1.03);
                }
            }

            @media screen and (max-width: $screen-md) {
                width: 100%;
                border-radius: 16px;
                padding: 5px 10px 5px 5px;
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .left-img {
                width: 200px;
                height: 140px;
                border-radius: 24px;
                object-fit: cover;
                flex-shrink: 0;
                transition: all 0.5s ease-in-out;

                @media screen and (max-width: $screen-md) {
                    width: 100px;
                    height: 70px;
                    border-radius: 16px;
                }
            }

            .center-box {
                flex: 1;
                color: $base-white;
                margin-left: 20px;

                @media screen and (max-width: $screen-md) {
                    margin-left: 6px;
                }

                .desc {
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 24px;
                    color: $base-black;
                    display: -webkit-box;
                    overflow: hidden;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;

                    @media screen and (max-width: $screen-md) {
                        font-size: 12px;
                        line-height: normal;
                    }
                }

                .title {
                    margin-top: 15px;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 18px;
                    color: #7e7e8a;

                    @media screen and (max-width: $screen-md) {
                        font-size: 10px;
                        line-height: normal;
                        font-weight: 400;
                    }
                }
            }
        }
    }
}
</style>
