var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "home-activity",
      class: { "home-activity-hide": _vm.blogList.length !== 2 },
    },
    [
      _c("div", { staticClass: "activite-title" }, [
        _c("span", [_vm._v(_vm._s(_vm.getI18n("title")))]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "more",
            on: {
              click: function ($event) {
                return _vm.goPage("blogs")
              },
            },
          },
          [
            _vm._v(
              "\n            " + _vm._s(_vm.getI18n("more")) + "\n        "
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "activite-wrap" },
        _vm._l(_vm.blogList, function (item, index) {
          return _c(
            "router-link",
            {
              key: index,
              staticClass: "activite-box",
              attrs: {
                to: {
                  name: "blogpage",
                  params: { blogPath: item.path },
                },
              },
            },
            [
              _c("ec-image", [
                _c("img", {
                  staticClass: "left-img",
                  attrs: { loading: "lazy", src: item.headerImageUrl, alt: "" },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "center-box" }, [
                _c("div", { staticClass: "desc" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(item.title) +
                      "\n                "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "title" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.formatTime(item.timeCreated)) +
                      "\n                "
                  ),
                ]),
              ]),
            ],
            1
          )
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }