<!-- @Author: wuhuiwang -->
<!-- @Date: 2023-6-27 14:12:18 -->
<!-- @Last Modified by: zhaoyang -->
<!-- @Last Modified time: 2023-10-19 18:16:21 -->

<template>
    <div class="partners-wrap">
        <div class="partners-content">
            <slot />
            <div
                v-for="(item, index) in partnersList"
                :key="index"
                class="partner-item"
            >
                <div class="item-title">
                    {{ item.title }}
                </div>
                <div
                    v-if="mounted"
                    class="partners-box"
                    :style="{height: !isMdScreen ? '50px' : ''}"
                >
                    <div>
                        <div :class="`cover cover1 ${coverClass}-1`" />
                        <vue-seamless-scroll
                            :key="scrollKey + index"
                            :data="item.images"
                            :class-option="classOption(item.images.length)"
                            class="swiperWidth"
                        >
                            <div class="box-wrap">
                                <div
                                    v-for="(it, ix) in item.images"
                                    :key="ix"
                                    class="box-item"
                                >
                                    <ec-image>
                                        <img
                                            loading="lazy"
                                            class="box-item"
                                            :src="it"
                                        >
                                    </ec-image>
                                </div>
                            </div>
                        </vue-seamless-scroll>
                        <div :class="`cover cover2 ${coverClass}-2`" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import p01 from '../img/partner-1.png';
import p10 from '../img/partner-10.png';
import p02 from '../img/partner-2.png';
import p03 from '../img/partner-3.png';
import p04 from '../img/partner-4.png';
import p05 from '../img/partner-5.png';
import p06 from '../img/partner-6.png';
import p07 from '../img/partner-7.png';
import p08 from '../img/partner-8.png';
import p09 from '../img/partner-9.png';

export default {
    name: 'Partners',

    props: {
        partnersListType: {
            type: String,
            default: 'Trusted'
        }
    },

    data() {
        return {
            mounted: false,
            isMdScreen: false,
            scrollKey: Date.now()
        };
    },

    computed: {
        classOption() {
            return function (num) {
                return {
                    step: this.isMdScreen ? 1 : 0.5,
                    limitMoveNum: num,
                    hoverStop: false,
                    direction: 2,
                    openTouch: true,
                    isSingleRemUnit: true,
                    openWatch: true,
                    screenWidth: ''
                };
            };
        },
        partnersList() {
            return this.partnersListType === 'Trusted' ? [
                {
                    title: 'Trusted Partners',
                    images: [p01, p02, p03, p04, p05, p06]
                }
            ] : [
                {
                    title: 'Regulator Partners',
                    images: [p07, p08, p09, p10]
                }
            ];
        },
        coverClass() {
           return this.partnersListType === 'Trusted' ? '' : 'bg-cover';
        }
    },

    mounted() {
        this.$options.components.vueSeamlessScroll = () => import('vue-seamless-scroll');
        this.mounted = true;
        this.scrollKey = Date.now();
        this.changeScreen();
        window.addEventListener('resize', this.changeScreen);
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.changeScreen);
    },

    methods: {
        changeScreen() {
            const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            this.screenWidth = screenWidth;
            this.isMdScreen = screenWidth > 768;
            if (this.screenWidth !== screenWidth) {
                setTimeout(() => {
                    this.scrollKey = Date.now();
                }, 100);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import "~mabilis-cash/common/style/index";

.partners-wrap {
    width: 100%;
    position: relative;

    .partner-item {
        margin-bottom: 100px;

        &:last-child {
            margin-bottom: 0;
        }

        .item-title {
            color: #000;
            font-size: 56px;
            font-weight: 700;
            line-height: 80px;
            margin-bottom: 30px;
        }

        @media screen and (max-width: $screen-md) {
            margin-bottom: 40px;

            .item-title {
                font-size: 24px;
                line-height: 24px;
                margin-bottom: 20px;
            }
        }
    }

    .partners-box {
        height: 100px;
        display: flex;
        align-items: center;
        overflow: hidden;
        position: relative;

        // opacity: 0.7;

        @media screen and (max-width: $screen-md) {
            height: auto;
        }

        .box-wrap {
            display: flex;
            flex-wrap: nowrap;
            width: fit-content;
            height: 100px;

            .box-item {
                width: 200px;
                height: auto;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center bottom;
                position: relative;
                flex-shrink: 0;
                margin-right: 80px;
            }

            @media screen and (max-width: $screen-md) {
                height: 50px;

                .box-item {
                    width: 100px;
                    margin-right: 10px;
                }
            }
        }

        .cover {
            width: 100px;
            height: 100px;
            position: absolute;
            top: 0;
            z-index: 99;

            @media screen and (max-width: $screen-md) {
                width: 50px;
                height: 50px;
            }
        }

        .cover1 {
            left: 0;
            background: linear-gradient(to right, rgb(255, 255, 255), rgba(255, 255, 255, 0));
        }

        .cover2 {
            right: 0;
            background: linear-gradient(to left, rgb(255, 255, 255), rgba(255, 255, 255, 0));
        }

        .bg-cover-1 {
            left: 0;
            background: linear-gradient(to right, rgb(240, 244, 248), rgba(240, 244, 248, 0));
        }

        .bg-cover-2 {
            right: 0;
            background: linear-gradient(to left, rgb(240, 244, 248,), rgba(240, 244, 248, 0));
        }

        .noScroll-box-wrap {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 100px;

            .noScroll-item {
                width: calc(20% - 8px);
                height: 100px;
                flex-shrink: 0;
                border-radius: 16px;
                background: #f9f9fa;
                margin-right: 10px;
                display: flex;
                align-items: center;
                justify-content: space-around;
                padding: 0 20px;

                &:last-child {
                    margin-right: 0;
                }

                .title {
                    color: #000;
                    font-size: 16px;
                    line-height: 24px;
                    width: 100px;
                }

                img {
                    height: auto;
                }
            }

            @media screen and (max-width: $screen-md) {
                flex-wrap: wrap;
                height: auto;

                .noScroll-item {
                    width: 100%;
                    height: 70px;
                    margin-right: 0;
                    padding: 0 20px;
                    margin-bottom: 10px;
                    justify-content: space-between;

                    .title {
                        font-size: 14px;
                        line-height: 18px;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .swiperWidth {
            display: flex;
            align-items: center;
            width: 100%;
            overflow: hidden;

            ::v-deep {
                div {
                    display: flex !important;
                    overflow: unset !important;
                }
            }
        }
    }
}
</style>
