var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "borrow" },
    [
      _c("div", { staticClass: "borrow-banner" }, [
        _c("div", { staticClass: "banner-main" }, [
          _c("div", { staticClass: "ph-container" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "reach-show",
                    rawName: "v-reach-show",
                    value: "elegant-in",
                    expression: "'elegant-in'",
                  },
                ],
                staticClass: "banner-content",
              },
              [
                _c("div", { staticClass: "banner-text" }, [
                  _c("div", { staticClass: "ph-title banner-title" }, [
                    _vm._v(
                      "\n                            " +
                        _vm._s(_vm.$t("borrow.banner.title")) +
                        "\n                        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "ph-context banner-context" }, [
                    _vm._v(
                      "\n                            " +
                        _vm._s(_vm.$t("borrow.banner.text")) +
                        "\n                        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "download-app" },
                    [
                      _c("app-store-button", {
                        attrs: { type: "google", theme: "light" },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("borrow-card", [
        _c("div", { staticClass: "feature-content" }, [
          _c("div", { staticClass: "ph-title feature-title" }, [
            _vm._v(
              "\n                " +
                _vm._s(_vm.$t("borrow.features.title")) +
                "\n            "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "feature-card" },
            _vm._l(_vm.$t("borrow.features.card"), function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "feature-item",
                  class: {
                    "feature-item-active": _vm.currentFeatureIndex === index,
                  },
                  on: {
                    mouseenter: function ($event) {
                      _vm.currentFeatureIndex = index
                    },
                  },
                },
                [
                  _c("ec-image", [
                    _c("img", {
                      staticClass: "item-icon",
                      attrs: {
                        loading: "lazy",
                        src: _vm.featureImgList[index],
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-left" }, [
                    _c("div", {
                      staticClass: "item-title",
                      domProps: { innerHTML: _vm._s(item.title) },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-desc" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(item.text) +
                          "\n                        "
                      ),
                    ]),
                  ]),
                ],
                1
              )
            }),
            0
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("borrow-card", { attrs: { "background-color": "#F0F4F8" } }, [
        _c("div", { staticClass: "how-content" }, [
          _c(
            "div",
            { staticClass: "how-step" },
            [
              _c("div", { staticClass: "ph-title" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.$t("borrow.process.title")) +
                    "\n                "
                ),
              ]),
              _vm._v(" "),
              _vm._l(_vm.$t("borrow.process.card"), function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "step-item",
                    class: {
                      "step-item-active": _vm.currentHowIndex === index,
                    },
                    on: {
                      mouseenter: function ($event) {
                        return _vm.handleClickHowIndex(index)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "step-number" }, [
                      _vm._v(
                        "\n                        0" +
                          _vm._s(index + 1) +
                          "\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", [_vm._v(_vm._s(item))]),
                  ]
                )
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "how-img-wrap" }, [
            _c("img", {
              staticClass: "how-img",
              attrs: { src: _vm.howImgList[_vm.currentHowIndex] },
            }),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "borrow-card",
        {
          staticClass: "user-borrow-card",
          attrs: { "background-color": "#FFF" },
        },
        [_c("borrow-user", { staticClass: "home-user-content" })],
        1
      ),
      _vm._v(" "),
      _c(
        "borrow-card",
        { attrs: { "background-color": "#F0F4F8" } },
        [_c("borrow-activity")],
        1
      ),
      _vm._v(" "),
      _c(
        "borrow-card",
        [_c("borrow-partners", { attrs: { "partners-list-type": "Trusted" } })],
        1
      ),
      _vm._v(" "),
      _c(
        "borrow-card",
        { attrs: { "background-color": "#F0F4F8" } },
        [
          _c("borrow-partners", {
            attrs: { "partners-list-type": "Gegulator" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }